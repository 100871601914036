@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~leaflet/dist/leaflet.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100&display=swap');

body,
html {
  font-family: 'Poppins', sans-serif !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.draggable {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}

.draggable-category {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem;
}

h2 {
  margin: 0;
}

.drag-handle {
  margin-top: inherit;
  border: 1px solid rgb(209, 209, 209);
  background: rgb(209, 209, 209);
  padding: 0.1rem;
  border-radius: 4px;
}

.category-container {
  width: 90%;
}

.item {
  border: 2px solid gray;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  background: lightgray;
}

.leaflet-container {
  height: 400px;
  width: 400px;
  border: 1px solid #ccc;
}

.address-dialog .leaflet-container {
  margin: 5px 20px;
  height: 300px;
  width: 300px;
  /* border: 1px solid #ccc; */
}


/* 
.leaflet-pane{
  width: 200px;
} */

.address-dialog .leaflet-map-pane {
  width: 100% !important;
}


/* 


.address-dialog .leaflet-div-icon {
  background: transparent;
  border: none;
} */


/* 
.address-dialog .leaflet-pane{
  width: 200px;
} */


/* Custom Scrollbar for Table Only */

.custom-table-scroll::-webkit-scrollbar {
  height: 6px;
  /* Horizontal scrollbar height */
}

.custom-table-scroll::-webkit-scrollbar-track {
  background: #1a1a1a;
  /* Dark background */
  border-radius: 10px;
}

.custom-table-scroll::-webkit-scrollbar-thumb {
  background: #555;
  /* Gray scrollbar */
  border-radius: 10px;
  transition: background 0.3s;
}

.custom-table-scroll::-webkit-scrollbar-thumb:hover {
  background: #888;
  /* Lighter on hover */
}


/* Custom Scrollbar for Dashboard Only */

.custom-dashboard-scroll::-webkit-scrollbar {
  height: 2px;
  /* Horizontal scrollbar height */
  width: 4px;
}

.custom-dashboard-scroll::-webkit-scrollbar-track {
  background: #1a1a1a;
  /* Dark background */
  border-radius: 6px;
}

.custom-dashboard-scroll::-webkit-scrollbar-thumb {
  background: #555;
  /* Gray scrollbar */
  border-radius: 6px;
  transition: background 0.3s;
}

.custom-dashboard-scroll::-webkit-scrollbar-thumb:hover {
  background: #888;
  /* Lighter on hover */
}